<template>

    <b-overlay :show="loading"
               :variant="variant"
               :opacity="opacity"
               :blur="blur"
               spinner-variant="primary"
               spinner-type="grow"
               spinner-large
               class="loaderPreview"
               rounded="sm">
        <b-row>
            <b-col lg="12">
                <div class="post" v-if="error">
                    <div v-if="error" class="error">
                        {{ error }}
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="(meta.breadcrumb || meta.pageTitle) && dataLoaded"
               class="content-header">

            <b-col class="content-header-left mb-2"
                   cols="12"
                   md="6">
                <b-row class="breadcrumbs-top">
                    <b-col cols="12">
                        <h2 class="content-header-title float-left pr-1 mb-0">
                            {{ showAllProjects==true? meta.pageTitle:'Archive Projects' }}
                        </h2>
                        <div class="breadcrumb-wrapper">
                            <b-breadcrumb>
                                <b-breadcrumb-item to="/">
                                    <feather-icon icon="HomeIcon"
                                                  size="16"
                                                  class="align-text-top" />
                                </b-breadcrumb-item>
                                <b-breadcrumb-item v-for="item in meta.breadcrumb"
                                                   :key="item.text"
                                                   :active="item.active"
                                                   :to="item.to">
                                    {{ item.text }}
                                </b-breadcrumb-item>
                            </b-breadcrumb>
                        </div>
                    </b-col>
                </b-row>
            </b-col>

            <!-- Content Right -->
            <b-col class="content-header-right text-md-right d-md-block d-none mb-1"
                   md="6"
                   cols="12">
                <b-button v-if="showAllProjects"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="showAllProjects=false"
                          type="button"
                          class="mr-1">
                    <feather-icon icon="EyeIcon" size="18" />
                    Archived Projects
                </b-button>

                <b-button v-if="!showAllProjects" ary
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-info"
                          @click="showAllProjects=true"
                          type="button"
                          class="mr-1">
                    <feather-icon icon="EyeIcon" size="18" />
                    All Projects
                </b-button>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-modal.addProjModal
                          type="button"
                          style="float: right;"
                          variant="outline-success">
                    <feather-icon icon="PlusIcon" size="18" />
                    New Project
                </b-button>
            </b-col>
        </b-row>

        <content v-if="dataLoaded" class="blog-wrapper">



            <b-row v-if="showAllProjects" class="blog-list-wrapper userprojects">
                <b-col xl="3"
                       lg="4"
                       md="4"
                       class="custom-col-4"
                       v-if="projResultCount > 0"
                       v-for="proj in pageArr"
                       v-bind:key="proj.Id">

                    <b-card :title="proj.Name">
                        <expandable-image :src="proj.Icon===''?require('@/assets/images/slider/04.jpg'): SubDomainURL +'/' +userFilesDir +'/'+proj.Icon+'?a='+randomStr" />
                        <b-card-text class="pt-1">
                            {{getYearsMonthsDays(proj.LastUpadte)}}
                        </b-card-text>

                        <b-row>
                            <b-col cols="12"
                                   class="border-top py-1">


                                <b-button :to="'/projectdetail/' + proj.Id"
                                          title="View Project"
                                          v-b-tooltip.hover.v-primary
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          variant="outline-primary"
                                          class="pull-left">
                                    <feather-icon icon="EyeIcon" />
                                    View Project
                                </b-button>

                                <b-button :data-id="proj.Id"
                                          title="Archive"
                                          v-b-tooltip.hover.v-danger
                                          @click="deleteProjectsData"
                                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                          variant="flat-danger"
                                          class="btn-icon pull-right">
                                    <feather-icon icon="ArchiveIcon" />
                                </b-button>

                                <b-button :data-id="proj.Id"
                                          :data-GoalId="proj.FunnelGoalId"
                                          title="Clone"
                                          v-b-tooltip.hover.v-info
                                          @click="cloneProjectsData"
                                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                          variant="flat-info"
                                          class="btn-icon pull-right">
                                    <feather-icon icon="CopyIcon" />
                                </b-button>



                            </b-col>
                        </b-row>
                    </b-card>

                </b-col>
                <b-col lg="12" v-if="projResultCount < 1">
                    <div class="demo-spacing-0">
                        <b-alert variant="primary"
                                 show>
                            <div class="alert-body bg-primary text-center">
                                <span style="color:#fff">
                                    No Project Exists
                                </span>
                            </div>
                        </b-alert>
                    </div>
                </b-col>

                <b-col cols="12"
                       v-if="projResultCount > 0"
                       class="text-center">
                    <v-page :total-row="projResultCount"
                            align="left"
                            :class="this.pageArr.length < 100?'hidden':''"
                            v-model="currentPage"
                            @page-change="pageProjectsChange"
                            :page-size-menu="[100, 200, 500, 1000]"></v-page>
                </b-col>
            </b-row>

            <b-row v-if="!showAllProjects" class="blog-list-wrapper userprojects">
                <b-col xl="3"
                       lg="4"
                       md="4"
                       class="custom-col-4"
                       v-if="archiveResultCount > 0"
                       v-for="archproj in archivedProjects"
                       v-bind:key="archproj.Id">
                    <b-card :title="archproj.Name">
                        <expandable-image :src="archproj.Icon===''?require('@/assets/images/slider/04.jpg'): SubDomainURL +'/' +userFilesDir +'/'+archproj.Icon+'?a='+randomStr" />
                        <b-card-text class="pt-1">
                            {{getYearsMonthsDays(archproj.LastUpadte)}}
                        </b-card-text>

                        <b-row class="text-center mx-0">
                            <b-col cols="12"
                                   class="border-top py-1">
                                <h3 class="font-weight-bolder mb-0">
                                    <b-button 
                                              :data-id="archproj.Id"
                                              :data-GoalId="archproj.FunnelGoalId"
                                              title="Restore"
                                              v-b-tooltip.hover.v-primary
                                              @click="unArchiveProjectsData"
                                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                              variant="outline-primary"
                                              class="btn-icon mr-1">
                                        <feather-icon icon="CornerUpLeftIcon" />
                                    </b-button>
                                </h3>
                            </b-col>
                        </b-row>
                    </b-card>

                </b-col>
                <b-col lg="12" v-if="archiveResultCount < 1">

                    <div class="demo-spacing-0">
                        <b-alert variant="primary"
                                 show>
                            <div class="alert-body bg-primary text-center">
                                <span style="color:#fff">
                                    No Archive Project Exists
                                </span>
                            </div>
                        </b-alert>
                    </div>
                </b-col>
            </b-row>

            <!--<div slot="sidebar"
                 class="blog-sidebar py-2 py-lg-0">



                <div class="blog-recent-posts">
                    <h6 class="section-label mb-75">
                        Recent Projects
                    </h6>
                    <b-media v-if="lastResultCount>0"
                             v-for="(recentproj,index) in lastUpdatedProjects"
                             :key="recentproj.Id"
                             no-body
                             :class="index? 'mt-2':''">
                        <b-media-aside class="mr-2">
                            <b-link :to="'/projectdetail/' + recentproj.Id">

                                <b-img :src="recentproj.Icon===''?require('@/assets/images/slider/04.jpg'): SubDomainURL +'/' +userFilesDir +'/'+recentproj.Icon"
                                       width="100"
                                       rounded
                                       height="70" />

                            </b-link>
                        </b-media-aside>
                        <b-media-body>
                            <h6 class="blog-recent-post-title">
                                <b-link :to="'/projectdetail/' + recentproj.Id"
                                        class="text-body-heading">
                                    {{ recentproj.Name }}
                                </b-link>
                            </h6>
                            <span class="text-muted mb-0">
                                {{getYearsMonthsDays(recentproj.LastUpadte)}}
                            </span>
                        </b-media-body>
                    </b-media>
                    <div v-if="lastResultCount<1">
                        No Project Exists
                    </div>
                </div>

                <div class="mt-3">
                    <b-button v-if="showAllProjects"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-primary"
                              @click="showAllProjects=false"
                              type="button"
                              block>
                        <feather-icon icon="EyeIcon" size="18" />
                        Archived Projects
                    </b-button>

                    <b-button v-if="!showAllProjects"ary
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-info"
                              @click="showAllProjects=true"
                              type="button"
                              block>
                        <feather-icon icon="EyeIcon" size="18" />
                        All Projects
                    </b-button>
                </div>
            </div>-->


            <b-modal id="addProjModal" centered size="lg" title="Add New Project">

                <validation-observer ref="projectNameRules">
                    <form ref="BuildProject" id="BuildProject" @submit.stop.prevent>
                        <section>

                            <b-form-row>
                                <b-col lg="12">
                                    <b-form-group>
                                        <label for="ProjectName">Name<span class="required"> *</span></label>
                                        <validation-provider #default="{ errors }"
                                                             rules="required"
                                                             name="Project Name">
                                            <b-form-input id="ProjectName"
                                                          v-model="ProjectName"
                                                          placeholder="Please Enter Project Name"
                                                          :state="errors.length > 0 ? false : null" />
                                            <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <b-row class="match-height" v-if="FunnelTypes">
                                <b-col lg="6">
                                    <b-card no-body
                                            class="shadow-none">
                                        <b-card-header class="text-center">
                                            <b-form-radio @change="rdoProjectTypeEmail"
                                                          name="rdoProjectType"
                                                          v-model="ProjectType"
                                                          value="Email">
                                                Collect Emails
                                            </b-form-radio>
                                        </b-card-header>
                                        <b-card-body class="text-center">
                                            <img class="headineimg"
                                                 src="@/assets/images/icons/mail.png"
                                                 alt="Mail"
                                                 style="width: 100px" />
                                            <div class="text">
                                                Build a new email list and start building an engaging email list.
                                            </div>
                                        </b-card-body>

                                    </b-card>

                                </b-col>

                                <b-col lg="6">
                                    <b-card no-body
                                            class="shadow-none">
                                        <b-card-header class="text-center">
                                            <b-form-radio @change="rdoProjectTypeSale"
                                                          name="rdoProjectType"
                                                          v-model="ProjectType"
                                                          value="Sale">
                                                Sales Funnel
                                            </b-form-radio>
                                        </b-card-header>
                                        <b-card-body class="text-center">

                                            <img class="headineimg"
                                                 src="@/assets/images/icons/sales.png"
                                                 alt="Mail"
                                                 style="width: 100px" />
                                            <div class="text">
                                                Sell your products after collecting leads with a squeeze page.
                                            </div>


                                        </b-card-body>

                                    </b-card>

                                </b-col>
                            </b-row>


                        </section>
                    </form>
                </validation-observer>
                <slot></slot>
                <template #modal-footer="{ cancel, ok }" v-bind:hide-footer="true">
                    <b-button @click="closeProjectModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>

                    <b-button @click="BuildProject"
                              id="btnGoalOneStep"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="SaveIcon" size="18" />
                        Build Project
                    </b-button>

                </template>

            </b-modal>

            <b-modal id="addProjModal1" centered size="lg" title="Add New Project">

                <validation-observer ref="simpleRules">
                    <form ref="ProjectCreate" id="ProjectCreate" @submit.stop.prevent>
                        <section>
                            <b-row class="match-height" v-if="FunnelTypes">
                                <b-col lg="6">
                                    <b-card no-body
                                            class="shadow-none">
                                        <b-link data-id="1" id="btnGoal_1" @click="ShowEmailFunnel">
                                            <b-card-header>
                                                <h4 class="mb-0">Collect Emails</h4>
                                            </b-card-header>
                                            <b-card-body class="text-center">
                                                <img class="headineimg"
                                                     src="@/assets/images/icons/mail.png"
                                                     alt="Mail"
                                                     style="width: 100px" />
                                                <div class="text">
                                                    Build a new email list and start building an engaging email list.
                                                </div>
                                            </b-card-body>
                                        </b-link>
                                    </b-card>
                                </b-col>

                                <b-col lg="6">
                                    <b-card no-body
                                            class="shadow-none">
                                        <b-link data-id="1"
                                                id="btnGoalTwoType_1"
                                                @click="ShowSaleFunnel">
                                            <b-card-header>
                                                <h4 class="mb-0">Sales Funnel</h4>
                                            </b-card-header>
                                            <b-card-body class="text-center">
                                                <img class="headineimg"
                                                     src="@/assets/images/icons/sales.png"
                                                     alt="Mail"
                                                     style="width: 100px" />
                                                <div class="text">
                                                    Sell your products after collecting leads with a squeeze page.
                                                </div>
                                            </b-card-body>
                                        </b-link>
                                    </b-card>
                                </b-col>
                            </b-row>

                            <div id="FunnalGoalOneSteps" v-if="GoalOne">
                                <b-form novalidate class="needs-validation" @submit.prevent>
                                    <b-form-row>
                                        <b-col lg="12">
                                            <b-form-group>
                                                <label for="GoalOneStepName">Project Name<span class="required"> *</span></label>
                                                <validation-provider #default="{ errors }"
                                                                     rules="required"
                                                                     name="Project Name">
                                                    <b-form-input id="GoalOneStepName"
                                                                  v-model="GoalOneStepNameVal"
                                                                  placeholder="Please Enter Project Name"
                                                                  :state="errors.length > 0 ? false : null" />
                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>
                                </b-form>
                            </div>

                            <div id="FunnalGoalTwoTypeOneSteps" v-if="GoalTwo">
                                <b-form novalidate class="needs-validation" @submit.prevent>
                                    <b-form-row>
                                        <b-col lg="12">
                                            <b-form-group>
                                                <label for="GoalTwoTypeOneStepName">Project Name<span class="required"> *</span></label>
                                                <validation-provider #default="{ errors }"
                                                                     rules="required"
                                                                     name="Project Name">
                                                    <b-form-input id="GoalTwoTypeOneStepName"
                                                                  v-model="GoalTwoTypeOneStepNameVal"
                                                                  placeholder="Please Enter Project Name"
                                                                  :state="errors.length > 0 ? false : null" />
                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>
                                </b-form>
                            </div>
                        </section>
                    </form>
                </validation-observer>
                <slot></slot>
                <template #modal-footer="{ cancel, ok }" v-bind:hide-footer="true" v-if="showEmailBuildButton || CreateSaleFunnel">
                    <b-button v-if="showGoBack"
                              @click="GoBack"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="ArrowLeftCircleIcon" size="18" />
                        Go Back
                    </b-button>

                    <b-button v-if="showEmailBuildButton"
                              @click="CreateEmailFunnel"
                              id="btnGoalOneStep"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="FilterIcon" size="18" />
                        Build Project
                    </b-button>

                    <b-button v-if="showSaleBuildButton"
                              @click="CreateSaleFunnel"
                              id="btnGoalTwoTypeOne"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="FilterIcon" size="18" />
                        Build Project
                    </b-button>
                </template>

            </b-modal>

        </content>
    </b-overlay>
</template>
<style>
    /*[dir] .dark-layout .modal .modal-header, [dir] .dark-layout .modal .modal-header[class*=bg-] {
        background-color: #283046;
    }

    [dir] .dark-layout .modal .modal-content, [dir] .dark-layout .modal .modal-body, [dir] .dark-layout .modal .modal-footer {
        background-color: #161d31;
        border-color: #3b4253;
    }*/
</style>
<script>
    import BCardCode from "@core/components/b-card-code";

    import {
        BCard,
        BCardHeader,
        BCardBody,
        BCardImg,
        BRow,
        BCol,
        BCardText,
        BCardTitle,
        BCardSubTitle,
        BLink,
        BButton,
        BModal,
        VBModal,
        BFormInput,
        BForm,
        BFormRow,
        BFormRadio,
        BFormValidFeedback,
        BFormInvalidFeedback,
        BFormGroup,
        BTabs,
        BTab,
        BOverlay,
        BImg,
        BMedia, BAvatar, BMediaAside, BMediaBody,
        BInputGroup, BInputGroupAppend, BPagination,
        BBreadcrumb, BBreadcrumbItem,
        VBTooltip,
        BAlert,
    } from "bootstrap-vue";
    import { ValidationProvider, ValidationObserver } from "vee-validate";

    import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
    import {
        required,
        email,
        confirmed,
        url,
        between,
        alpha,
        integer,
        password,
        min,
        digits,
        alphaDash,
        length,
    } from "@validations";
    import { $themeConfig } from "@themeConfig";
    import Ripple from "vue-ripple-directive";
    import jwt from "@/auth/jwt/useJwt";

    //import { vPage } from 'v-page'

    import { extend } from 'vee-validate';
    extend('required', {
        message: 'This Field is required',
    });
    extend('email', {
        message: 'Invalid email address',
    });

    export default {
        metaInfo: {
            title: 'Landingly | Projects'
        },
        components: {
            BCardCode,
            BCardText,
            BCard,
            BCardHeader,
            BRow,
            BCol,
            BCardBody,
            BCardImg,
            BLink,
            BModal,
            VBModal,
            BButton,
            BFormInput,
            BForm,
            BFormRow,
            BFormRadio,
            BFormValidFeedback,
            BFormInvalidFeedback,
            BFormGroup,
            BTabs,
            BTab,
            ValidationProvider,
            ValidationObserver,
            BOverlay,
            BCardTitle,
            BCardSubTitle,
            ContentWithSidebar,
            BImg,
            BMedia, BAvatar, BMediaAside, BMediaBody,
            BInputGroup, BInputGroupAppend, BPagination,
            BBreadcrumb, BBreadcrumbItem,
            BAlert,
        },
        data() {
            return {
                loading: true,
                variant: "light",
                opacity: 0.85,
                blur: "2px",

                meta: {
                    pageTitle: 'Projects',
                    breadcrumb: [
                        {
                            text: 'Projects',
                            active: true
                        },
                    ],
                },

                ProjectType: 'Email',
                GoalId: 1,
                GoalTypeId: 0,
                ProjectName: '',

                pageArr: [],
                projects: [],
                archivedProjects: null,
                lastUpdatedProjects: null,
                upSellUserConfiguration: null,
                IsEmpirelyUser: false,
                error: null,
                GoalOneStepNameVal: "",
                GoalTwoTypeOneStepNameVal: "",
                GoalOne: false,
                GoalTwo: false,
                FunnelTypes: true,

                WebAPIURL: $themeConfig.app.apiURL,
                SubDomainURL: null,
                filesDir: $themeConfig.app.UserTemplateFilesDir,
                UserDomainURL: $themeConfig.app.UserDomainURL,
                userFilesDir: $themeConfig.app.UserDomainFilesDir,

                archiveResultCount: 0,
                lastResultCount: 0,
                projResultCount: 0,

                dataLoaded: false,
                randomStr: null,

                showBuildButton: false,
                showEmailBuildButton: false,
                showSaleBuildButton: false,
                showGoBack: false,
                search_query: null,
                showAllProjects: true,
                currentPage: 0,
                perPage: 100,
                rows: 140,
                pageInfo: [
                    {
                        pageSize: 100,
                        pageNumber: 0,
                    }
                ]
            };
        },
        directives: {
            "b-modal": VBModal,
            'b-tooltip': VBTooltip,
            Ripple,
        },
        created() {
            //document.querySelector('a[href*="/projects"]').parentNode.classList.add("active");

            /*Currently Commented due to pagination function pageProjectsChange*/

            var subDomain = jwt.getSubDomain();
            //if (subDomain == null || subDomain == "" || subDomain == 'undefined') {
            //    //this.$router.replace('/setupuseraccount')
            //    this.$router.replace('/login')
            //}
            //else {
            if (this.UserDomainURL.includes("localhost")) {
                this.SubDomainURL = this.UserDomainURL;
            }
            else {
                subDomain = subDomain.replace('"', '');
                this.SubDomainURL = this.UserDomainURL.replace('https://', 'https://' + subDomain + '.');
            }

            this.fetchProjectsData(this.pageInfo);
            //}
        },
        beforeCreate() {
            
            //else {
            //    if (this.UserDomainURL.includes("localhost")) {
            //        this.SubDomainURL = this.UserDomainURL;
            //    }
            //    else {
            //        subDomain = subDomain.replace('"', '');
            //        this.SubDomainURL = this.UserDomainURL.replace('https://', 'https://' + subDomain + '.');
            //    }

            //    this.fetchProjectsData(this.pageInfo);
            //}
        },
        watch: {
            $route: "fetchProjectsData",
        },

        methods: {

            pageProjectsChange: function (pInfo) {
                if ((this.projects == null || this.projects.length < 1) && this.projResultCount === 0) {
                    this.fetchProjectsData(pInfo);
                }
                else {
                    this.loadPageData(pInfo)
                }
            },

            loadPageData(pInfo) {
                this.loading = true;

                if (this.pageArr.length > 0) {
                    this.pageArr = [];
                }
                this.pageArr.splice(0, this.pageArr.length);
                let start = 0,
                    end = 0;
                start = pInfo.pageSize * (pInfo.pageNumber - 1);

                if (start < 0) {
                    start = 0;
                }
                end = start + pInfo.pageSize;
                console.log("this.pageArr ", this.pageArr);
                if (end > this.projects.length) end = this.projects.length;
                for (let i = start; i < end; i++) {
                    this.pageArr.push(this.projects[i]);
                }
                this.loading = false;
            },

            showArchiveProjects() {
                this.showAllProjects = false;
            },
            GoBack() {
                this.GoalOne = false;
                (this.GoalTwo = false), (this.FunnelTypes = true);
                this.showEmailBuildButton = false;
                this.showSaleBuildButton = false;
                this.showGoBack = false;
            },

            ShowEmailFunnel() {
                this.GoalOne = true;
                (this.GoalTwo = false), (this.FunnelTypes = false);
                this.showEmailBuildButton = true;
                this.showSaleBuildButton = false;
                this.showGoBack = true;
            },

            ShowSaleFunnel() {
                this.GoalOne = false;
                this.GoalTwo = true;
                this.FunnelTypes = false;
                this.showEmailBuildButton = false;
                this.showSaleBuildButton = true;
                this.showGoBack = true;
            },

            rdoProjectTypeEmail() {
                this.GoalId = "1";
                this.GoalTypeId = "0";
            },

            rdoProjectTypeSale() {
                this.GoalId = "2";
                this.GoalTypeId = "1";
            },

            closeProjectModal() {
                this.$bvModal.hide("addProjModal");
            },

            BuildProject: async function () {
                this.$refs.projectNameRules.validate().then((success) => {
                    if (success) {
                        if (this.GoalId == 1) {
                            
                            if (this.upSellUserConfiguration.UpSellId == null || this.upSellUserConfiguration.TotalOtinProject < this.upSellUserConfiguration.NoOfOptinPage) {
                                this.createPorjectCall();
                            }
                            else {
                                this.displayLimitExceededWarning("create a new optin project");
                            }
                        }
                        else if (this.GoalId == 2) {
                            if (this.upSellUserConfiguration.UpSellId == null || this.upSellUserConfiguration.TotalSaleProject < this.upSellUserConfiguration.NoOfSalePage) {
                                this.createPorjectCall();
                            }
                            else {
                                this.displayLimitExceededWarning("create a new sale project");
                            }
                        }
                    }
                });
            },

            createPorjectCall: async function () {
                const access_token = await jwt.getToken();
                const funnelName = encodeURIComponent(this.ProjectName);

                var self = this;
                this.loading = true;

                var axios = require("axios");
                var qs = require("qs");

                var data = qs.stringify({});
                var config = {
                    method: "post",
                    url:
                        "" +
                        this.WebAPIURL +
                        "/api/projects/AddFunnel?Name=" + funnelName + "&GId=" + this.GoalId + "&GTId=" + this.GoalTypeId + "",
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        self.loading = false;
                        self.$router.push("/projectdetail/" + response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.loading = false;
                    });
            },

            displayLimitExceededWarning: function (projectType) {
                const externalURL = 'https://app.empirely.ai/upgrade';
                this.$swal({
                    title: "Limit Exceed!",
                    html: "You have attempted to exceed the limit. Please upgrade your account to " + projectType + ".",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Upgrade",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        window.open(externalURL, '_blank');
                    }
                });
            },

            CreateEmailFunnel: async function () {
                const access_token = await jwt.getToken();
                this.$refs.simpleRules.validate().then((success) => {
                    if (success) {
                        const funnelName = this.GoalOneStepNameVal;
                        const GoalId = "1";
                        const GoalTypeId = "0";

                        var self = this;
                        this.loading = true;

                        var axios = require("axios");
                        var qs = require("qs");
                        this.showEmailBuildButton = false;
                        this.showSaleBuildButton = false;
                        this.showGoBack = false;

                        console.log("this.GoalId : " + GoalId);
                        console.log("this.GoalTypeId : " + GoalTypeId);

                        var data = qs.stringify({});
                        var config = {
                            method: "post",
                            url:
                                "" +
                                this.WebAPIURL +
                                "/api/projects/AddFunnel?Name=" + funnelName + "&GId=" + GoalId + "&GTId=" + GoalTypeId + "",
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };

                        axios(config)
                            .then(function (response) {
                                self.loading = false;
                                self.$router.push("/projectdetail/" + response.data);
                            })
                            .catch(function (error) {
                                console.log(error);
                                self.loading = false;
                            });

                    }
                });
            },

            CreateSaleFunnel: async function () {
                const access_token = await jwt.getToken();
                this.$refs.simpleRules.validate().then((success) => {
                    if (success) {
                        const funnelName = this.GoalTwoTypeOneStepNameVal;
                        const GoalId = "2";
                        const GoalTypeId = "1";

                        var self = this;
                        this.loading = true;
                        this.showGoBack = false;
                        this.showEmailBuildButton = false;
                        this.showSaleBuildButton = false;

                        console.log("this.GoalId : " + GoalId);
                        console.log("this.GoalTypeId : " + GoalTypeId);

                        var axios = require("axios");
                        var qs = require("qs");
                        var data = qs.stringify({});
                        var config = {
                            method: "post",
                            url:
                                "" +
                                this.WebAPIURL +
                                "/api/projects/AddFunnel?Name=" + funnelName + "&GId=" + GoalId + "&GTId=" + GoalTypeId + "",
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };

                        axios(config)
                            .then(function (response) {
                                self.loading = false;
                                self.$router.push("/projectdetail/" + response.data);
                            })
                            .catch(function (error) {
                                console.log(error);
                                self.loading = false;
                            });

                    }
                });
            },

            rndStr(len) {
                let text = ""
                let chars = "abcdefghijklmnopqrstuvwxyz0123456789"

                for (let i = 0; i < len; i++) {
                    text += chars.charAt(Math.floor(Math.random() * chars.length))
                }

                return text
            },

            fetchProjectsData: async function (pInfo) {
                this.error = this.projects = null;
                var self = this;
                this.loading = true;
                var axios = require("axios");
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };

                axios
                    .get("" + this.WebAPIURL + "/api/projects/GetProjects", { headers })
                    .then(function (response) {

                        self.projects = response.data.Funnels;
                        self.archivedProjects = response.data.ArchiveFunnels;
                        self.lastUpdatedProjects = response.data.TopLastUpdated;
                        self.upSellUserConfiguration = response.data.upSellConfig;
                        self.archiveResultCount = response.data.ArchiveFunnels.length;
                        self.IsEmpirelyUser = response.data.IsEmpirelyUser
                        

                        self.lastResultCount = response.data.TopLastUpdated.length;
                        self.projResultCount = response.data.Funnels.length;

                        self.dataLoaded = true;

                        self.loading = false;
                        self.randomStr = self.rndStr(10);

                        self.loadPageData(pInfo);
                        console.log("response.data:: " + response.data);

                        let arr = [];

                        response.data.Funnels.forEach((value, index) => {

                            console.log(value.Icon);
                        });

                        console.log('WebAPIURL:: ' + self.WebAPIURL);
                        console.log('filesDir:: ' + self.filesDir);
                    })
                    .catch(function (error) {
                        self.error = error;
                        self.loading = false;
                        self.dataLoaded = true;
                        console.log("error:: " + error);
                    });
            },

            cloneProjectsData: async function (e) {
                const projId = e.currentTarget.getAttribute("data-id");
                const goalId = e.currentTarget.getAttribute("data-GoalId");
                this.error = null;
                
                const access_token = await jwt.getToken();
                
                this.$swal({
                    title: "Clone Project",
                    text: "Note: Only Project Settings & Files will be copied. You need to manually configure Email Automation and Autoresponders. Are you sure you want to proceed.?",
                    //icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Clone",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        if (goalId == 1) {
                            if (this.upSellUserConfiguration.UpSellId == null || this.upSellUserConfiguration.TotalOtinProject < this.upSellUserConfiguration.NoOfOptinPage) {
                                this.cloneProjectApicall(access_token, projId);
                            }
                            else {
                                this.displayLimitExceededWarning("clone optin project");
                            }
                        }
                        else if (goalId == 2) {
                            if (this.upSellUserConfiguration.UpSellId == null || this.upSellUserConfiguration.TotalSaleProject < this.upSellUserConfiguration.NoOfSalePage) {
                                this.cloneProjectApicall(access_token, projId);
                            }
                            else {
                                this.displayLimitExceededWarning("clone sale project");
                            }
                        }
                        
                    }
                });
            },

            cloneProjectApicall: function (access_token, projId) {
                var self = this;
                var axios = require("axios");
                var qs = require("qs");
                self.loading = true;
                var data = qs.stringify({
                    UserFunnelId: projId,
                });

                var config = {
                    method: "post",
                    url: "" + self.WebAPIURL + "/api/projects/CloneUserProject?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };
                axios(config)
                    .then(function (response) {
                        self.loading = false;
                        self.pageInfo.pageSize = 100;
                        self.pageInfo.pageNumber = 0;

                        self.fetchProjectsData(self.pageInfo);
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.loading = false;
                    });
            },

            deleteProjectsData: async function (e) {
                const projId = e.currentTarget.getAttribute("data-id");

                this.error = null;
                var self = this;
                var axios = require("axios");
                const access_token = await jwt.getToken();
                var qs = require("qs");
                this.$swal({
                    title: "Archive Project",
                    html: "Are you sure you want to archive this Project? <br/> (You can unarchive this later.)",
                    //icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Archive",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        self.loading = true;
                        var data = qs.stringify({
                            Id: projId,
                            IsEmailSending: false
                        });

                        var config = {
                            method: "post",
                            url: "" + self.WebAPIURL + "/api/projects/ArchiveFunnel?" + data,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };
                        axios(config)
                            .then(function (response) {
                                self.loading = false;
                                self.pageInfo.pageSize = 100;
                                self.pageInfo.pageNumber = 0;
                                self.fetchProjectsData(self.pageInfo);
                            })
                            .catch(function (error) {
                                console.log(error);
                                self.loading = false;
                            });
                    }
                });
            },

            unArchiveProjectsData: async function (e) {
                const projId = e.currentTarget.getAttribute("data-id");
                this.error = null;
                const access_token = await jwt.getToken();
                const goalId = e.currentTarget.getAttribute("data-GoalId");
                if (goalId == 1) {
                    if (this.upSellUserConfiguration.UpSellId == null || this.upSellUserConfiguration.TotalOtinProject < this.upSellUserConfiguration.NoOfOptinPage) {
                        this.unArchiveProjectsApi(access_token, projId);
                    }
                    else {
                        this.displayLimitExceededWarning("Restore archived optin project");
                    }
                }
                else if (goalId == 2) {
                    if (this.upSellUserConfiguration.UpSellId == null || this.upSellUserConfiguration.TotalSaleProject < this.upSellUserConfiguration.NoOfSalePage) {
                        this.unArchiveProjectsApi(access_token, projId);
                    }
                    else {
                        this.displayLimitExceededWarning("Restore archived sale project");
                    }
                }

            },

            unArchiveProjectsApi: async function (access_token, projId) {
                this.error = null;
                var self = this;
                var axios = require("axios");
                var qs = require("qs");
                this.$swal({
                    title: "Restore Archived Project",
                    html: `<div>
                            <h6 style="color:#000;">Enable Email Sending?</h6>
                            <div class="demo-inline-spacing" style="justify-content: center;">
                               <div class="custom-control custom-radio" style="margin-top: 0.5rem !important;">
                                   <input id="Later" class="custom-control-input custom-radio-bg-color" type="radio" name="IsSendingEmail" value="false" checked>
                                   <label class="custom-control-label custom-radio-color" for="Later">No, I will do it later</label>
                               </div>
                               <div class="custom-control custom-radio" style="margin-top: 0.5rem !important;">
                                 <input id="Immediate" class="custom-control-input custom-radio-bg-color" type="radio" name="IsSendingEmail" value="true">
                                 <label class="custom-control-label custom-radio-color" for="Immediate">Yes, Immediately</label>
                               </div>
                            </div>
                        </div>`,
                    //icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Restore",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        self.loading = true;
                        const IsEmailSend = document.querySelector('input[name="IsSendingEmail"]:checked').value;
                        var data = qs.stringify({
                            Id: projId,
                            IsEmailSending: IsEmailSend
                        });

                        var config = {
                            method: "post",
                            url: "" + self.WebAPIURL + "/api/projects/RestoreArchivedFunnel?" + data,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };
                        axios(config)
                            .then(function (response) {
                                self.loading = false;
                                self.pageInfo.pageSize = 100;
                                self.pageInfo.pageNumber = 0;
                                self.showAllProjects = true;
                                self.fetchProjectsData(self.pageInfo);
                            })
                            .catch(function (error) {
                                console.log(error);
                                self.loading = false;
                            });
                    }
                });
            },

            getYearsMonthsDays(date) {
                var moment = require("moment");
                let fromTime = moment(date).diff(moment(), "milliseconds");
                let duration = moment.duration(fromTime);
                let years = duration.years() / -1;
                let months = duration.months() / -1;
                let days = duration.days() / -1;
                if (years > 0) {
                    var Ys = years == 1 ? years + " year and " : years + " years and ";
                    var Ms = months == 1 ? months + " month" : months + " months ";
                    var Ds = days == 1 ? days + " day" : days + " days";
                    return "Last Updated " + Ys + Ms + Ds + " Ago";
                } else if (months > 0) {
                    var MS = months == 1 ? months + " month " : months + " months ";
                    var Ds = days == 1 ? days + " day" : days + " days";
                    return "Last Updated " + MS + Ds + " Ago";
                } else if (days > 0) {
                    var Ds = days == 1 ? days + " day" : days + " days";
                    return "Last Updated " + Ds + " Ago";
                } else {
                    return "Last Updated Today";
                }
            },
        },
        destroyed() {
            //document.querySelector('a[href*="/projects"]').parentNode.classList.remove("active");
        },
    };
</script>

<style>
    /*.btn-icon svg.feather {
        width: 22px;
        height: 22px;
    }
    .card-body{
        padding:1.5rem;
    }*/
    [dir] .card .card-title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .swal2-title {
        color: #000 !important;
    }
</style>